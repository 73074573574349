import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import FlowSpinner from "./components/FlowSpinner";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Profile from "./views/Profile";
import ExternalApi from "./views/ExternalApi";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";
import crashrocket from "./assets/crash-rocket2.png";


import Button from '@mui/material/Button';

// styles
import "./App.css";
import "./index.scss";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();



const App = () => {
  const { isLoading, error } = useAuth0();

  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();

  if (error) {
    return (
      <div className="error-field-outer">
        <div className="error-field-inner">
          <div className="error-field-inner__text-field">
            <div className="error-field-inner__title">Oops...</div>
            <div className="error-field-inner__text">{error.message}</div>
          </div>
            <div className="error-field-inner__home-button">
              <Button className="px-4" style={{ fontSize: "16px", backgroundColor: "#992B24", color: "white" }}>
                <a href="https://spacetango.com/">
                  Fly Home
                </a>
              </Button>
            </div>
            <img className="error-field-inner__crashrocket" src={crashrocket} />
        </div>
      </div>
    );
  }

  if (isLoading) {
    // return <FlowSpinner />;
    return(
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}>
        <div className="logo-loading" />
      </div>
    )
    
  }

  if (!isAuthenticated) {
    loginWithRedirect();
  }

  return (
    <div className="main">
      {/* <div className="bg-img"/> */}
      <Router history={history}>
        <div id="app" className="main-container">
            <NavBar />
            {/* <FlowSpinner /> */}
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/profile" component={Profile} />
            </Switch>
        </div>
      </Router>
  </div>
  );
};

export default App;
