import configJson from "./auth_config.json";

export function getConfig() {
  /*TODO: parameterize after you update packages
  const configJson = {
      "domain": process.env.DOMAIN,
      "clientId": process.env.CLIENT_ID,
      "audience": process.env.AUDIENCE
  };*/
  const audience =
    configJson.audience && configJson.audience !== "YOUR_API_IDENTIFIER"
      ? configJson.audience
      : null;

  return {
    domain: configJson.domain,
    clientId: configJson.clientId,
    ...(audience ? { audience } : null),
  };
}
